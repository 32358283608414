import React from "react"
// import { navigate } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"

// import { useSelector } from "react-redux"
// import { getAuthUser } from "../../redux/slices/session/selectors"
import SponsorMedpar from "../../components/SponsorMedpar"
import CompfestTalksChannel from "../../components/CompfestTalksChannel"

const Webinar = () => {
  // const userData = useSelector(getAuthUser)

  // if (!userData) {
  //   typeof window !== "undefined" && navigate("/compfesttalks")
  // }

  return (
    <Layout>
      <div style={{ overflowX: "hidden", width: "100%", height: "100%" }}>
        <SEO title="COMPFEST Talks" />
        <div className="layout">
          <CompfestTalksChannel />
          <SponsorMedpar />
        </div>
      </div>
    </Layout>
  )
}

export default Webinar
