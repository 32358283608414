import React from "react"
import Button from "../Button"
import { ChannelCard, Container } from "./style"

const Channel = (props) => {
  const color = {
    opacity: "1",
    fontWeight: "bold",
    background: props.color,
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
    borderImageSource: props.color,
    WebkitBorderImage: props.color,
    borderImageSlice: "1",
  }

  return (
    <ChannelCard>
      <h4>CHANNEL {props.channelKey + 1}</h4>
      <h1 style={color}>{props.name}</h1>
      <p>{props.desc}</p>
      <Button
        to={props.url}
        state={{ channelKey: props.channelKey }}
        style={{ width: "250px" }}
      >
        WATCH NOW
      </Button>
    </ChannelCard>
  )
}

const CHANNEL_DATA = [
  {
    name: "INNOVATION",
    color: `linear-gradient(239.4deg, #00F7D2 8.19%, #3983D3 93.01%)`,
    url: "/compfesttalks/player",
    desc:
      "Be empowered and strive to make a change with innovators in the innovation channel",
    key: 0,
  },
  {
    name: "INFLUENCE",
    color: `linear-gradient(74.69deg, #006CE1 21.49%, #02DBFC 78.51%)`,
    url: "/compfesttalks/player",
    desc:
      "Learn how to leave an impact with influencers in the influence channel",
    key: 1,
  },
]

const CompfestTalksChannel = () => {
  return (
    <Container>
      <h1>CHOOSE YOUR CHANNEL</h1>
      <p>
        In collaboration with our guests, we have prepared 2 channels that you
        can pick based on topic!
      </p>
      <div className="gap-8px" />
      <div className="gap-8px" />
      <div className="gap-8px" />
      <div className="gap-8px" />
      <div className="gap-8px" />
      <div className="channel-box">
        {CHANNEL_DATA.map((channel) => {
          return (
            <Channel
              name={channel.name}
              desc={channel.desc}
              channelKey={channel.key}
              key={channel.key}
              url={channel.url}
              color={channel.color}
            />
          )
        })}
      </div>
    </Container>
  )
}

export default CompfestTalksChannel
