import styled from "styled-components"

export const Container = styled.div`
  margin: 64px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h1 {
    font-size: 3rem;
  }

  .gap-8px {
    margin: 8px 0 0 0;
  }

  .channel-box {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`

export const ChannelCard = styled.div`
  flex: 1 1 auto;
  padding: 3rem 2.5rem 2rem 2.5rem;
  margin: 1rem;
  background: #2e2e2e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  .gap-8px {
    margin: 8px 0 0 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 2.25rem;
  }

  h4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
  }
  h1 {
    margin: 0.5rem auto 1.25rem auto;
    @media (max-width: 865px) {
      font-size: 2.25rem;
    }
  }
`
